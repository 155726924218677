/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Images
import bgFront from "assets/images/img1.jpg";
import bgBack from "assets/images/img2.webp";

function Information() {
  return (
    <MKBox component="section" py={6} my={6}>
      <Container>
        <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                image={bgFront}
                icon="touch_app"
                title={<>Why US</>}
                description="Telemed Healthcare Solutions has key qualifications to stand out in the industry. Commitment to patient care: At Telemed Healthcare Solutions, we prioritize patient care above all else. We believe that our services can make a significant impact on patient outcomes, and we are dedicated to providing the highest quality care possible.
                "
              />
              <RotatingCardBack
                image={bgBack}
                title="Know More"
                description="You can contact us to know more about Telemed Healthcare Solutions."
                action={{
                  type: "internal",
                  route: "/contact-us",
                  label: "Contact Us",
                }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="content_copy"
                  title="Quality"
                  description="Telemed Healthcare Solutions has a team of expert radiologists who are licensed and certified ensuring accurate and timely diagnosis."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="flip_to_front"
                  title="Availability"
                  description="We offer 24/7 coverage and timely reports, providing healthcare providers with rapid access to radiology expertise, without the need for on-site radiologists."
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="price_change"
                  title="Subspecialty expertise"
                  description="We offer subspecialty radiology services to provide additional expertise and depth of knowledge in specific areas, such as neurology, MRK, Paediatrics, cardiology, and oncology."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="devices"
                  title="Customer service"
                  description="Our customer service team is available 24/7 to answer any questions or concerns, and we use secure communication channels to ensure the privacy and security of patient data and maintain HIPAA compliance.
                  "
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
