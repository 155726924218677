// @mui material components
import Container from "@mui/material/Container";
// import Grid from "@mui/material/Grid";
// import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
// import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";
import MKTypography from "components/MKTypography";

function Counters() {
  return (
    <MKBox component="section" py={3}>
      <Container>
        <MKTypography
          variant="h2"
          style={{ color: "#005fad" }}
          textAlign="left"
          px={{ xs: 6, lg: 12 }}
          mt={1}
        >
          Best Teleradiology Reporting Services You Can Trust.
        </MKTypography>
        <MKTypography variant="body1" color="grey" textAlign="left" px={{ xs: 6, lg: 12 }} mt={1}>
          If you are looking for a reliable teleradiology reporting services, the team at Telemed
          Healthcare Solutions are here to help. We are known for the best, and error free services
          in the medical field.
        </MKTypography>
        {/* <Grid container item xs={12} lg={9} sx={{ mx: "auto" }}>
          <Grid item xs={12} md={4}>
            <DefaultCounterCard
              count={70}
              suffix="+"
              title="Coded Elements"
              description="From buttons, to inputs, navbars, alerts or cards, you are covered"
            />
          </Grid>
          <Grid item xs={12} md={4} display="flex">
            <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, mx: 0 }} />
            <DefaultCounterCard
              count={15}
              suffix="+"
              title="Design Blocks"
              description="Mix the sections, change the colors and unleash your creativity"
            />
            <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, ml: 0 }} />
          </Grid>
          <Grid item xs={12} md={4}>
            <DefaultCounterCard
              count={4}
              title="Pages"
              description="Save 3-4 weeks of work when you use our pre-made pages for your website"
            />
          </Grid>
        </Grid> */}
      </Container>
    </MKBox>
  );
}

export default Counters;
