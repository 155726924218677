/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Icon from "@mui/material/Icon";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import bgImage from "assets/images/img1.jpg";

function BuiltByDevelopers() {
  return (
    <MKBox
      display="flex"
      alignItems="center"
      borderRadius="xl"
      my={2}
      py={6}
      paddingRight={6}
      sx={{
        backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
          `${linearGradient(
            rgba(gradients.dark.main, 0.8),
            rgba(gradients.dark.state, 0.8)
          )}, url(${bgImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Container>
        <Grid container item xs={12} lg={6} sx={{ ml: { xs: 0, lg: 6 } }}>
          {/* <MKTypography variant="h4" color="white" fontWeight="bold">
            Telemed heathcare solutions..
          </MKTypography> */}
          <MKTypography variant="h1" color="white" mb={1}>
            About Us
          </MKTypography>
        </Grid>
        <Grid container item xs={12} lg={12} sx={{ ml: { xs: 0, lg: 6 } }}>
          <MKTypography sx={{ textAlign: "justify" }} variant="body1" color="white" opacity={0.8}>
            Telemed Healthcare Solutions is a leading teleradiology company that specializes in
            providing high-quality remote radiology interpretation services to healthcare
            organizations and medical practices. We have a team of expert radiologists who are
            licensed and certified, and we use advanced technology and state-of-the-art equipment to
            ensure accurate and timely diagnosis. Our mission is to improve healthcare outcomes and
            enhance the overall quality of medical services. We are committed to providing our
            clients with the best possible care by offering 24/7 coverage and timely reports. Our
            teleradiology services allow healthcare providers to access radiology expertise quickly
            and efficiently, without the need for on-site radiologists. At Telemed Healthcare
            Solutions, we prioritize patient care above everything else. We believe that our
            services can make a significant impact on patient outcomes, and we are dedicated to
            providing the highest quality care possible. Our commitment to excellence, reliability,
            and innovation sets us apart in the teleradiology industry, and we are proud to serve as
            a trusted partner for healthcare organizations and medical practices across the globe.
          </MKTypography>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default BuiltByDevelopers;
