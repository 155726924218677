// react-router-dom components
// import { Link } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
// import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";
import routes from "routes";
import DefaultFooter from "examples/Footers/DefaultFooter";
import footerRoutes from "footer.routes";

// Presentation page components
// import ExampleCard from "pages/Presentation/components/ExampleCard";

// Data
import data from "pages/Presentation/sections/data/servicesData";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

function Services() {
  const renderData = data.map(({ title, description, items }) => (
    <Grid container spacing={3} sx={{ mb: 10 }} key={title}>
      <Grid item xs={12} lg={3}>
        <MKBox position="sticky" top="100px" pb={{ xs: 2, lg: 6 }}>
          <MKTypography variant="h3" fontWeight="bold" mb={1}>
            {title}
          </MKTypography>
          <MKTypography variant="body2" fontWeight="regular" color="secondary" mb={1} pr={2}>
            {description}
          </MKTypography>
        </MKBox>
      </Grid>
      <Grid item xs={12} lg={9}>
        <Grid container spacing={3}>
          {items.map(({ name }) => (
            <>
              <Grid item xs={12} md={12} sx={{ mb: 2 }} key={name}>
                {/* <ExampleCard image={image} name={name} count={count} pro={pro} /> */}
                {name}
              </Grid>
            </>
          ))}
        </Grid>
      </Grid>
    </Grid>
  ));

  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "/contact-us",
          label: "Contact Us",
          color: "info",
        }}
        sticky
      />
      <br />
      <br />
      <br />
      <br />
      <MKBox component="section">
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={6}
            flexDirection="column"
            alignItems="center"
            sx={{ textAlign: "center", mx: "auto" }}
          >
            {/* <MKBadge
            variant="contained"
            color="info"
            badgeContent="Infinite combinations"
            container
            sx={{ mb: 2 }}
          /> */}
            <MKTypography variant="h2" fontWeight="bold">
              Our Services
            </MKTypography>
          </Grid>
        </Container>
        <Container sx={{ mt: 6 }}>{renderData}</Container>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Services;
