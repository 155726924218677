export default [
  {
    title: "Teleradiology",
    description:
      "Our teleradiology services allow healthcare organizations and medical practices to ...",
    icon: "",
  },
  {
    title: "Preliminary Reports",
    description:
      "We offer preliminary reports to help healthcare providers quickly identify critical...",
    icon: "",
  },
  {
    title: "Final Reports",
    description:
      " Our final reports provide comprehensive radiology interpretation and diagnosis...",
    icon: "",
  },
  {
    title: "Quality Assurance",
    description:
      "Our radiologists undergo ongoing training and quality assurance measures to ensure...",
    icon: "",
  },
  {
    title: "Subspecialty Services",
    description:
      "We offer subspecialty radiology services to provide additional expertise and depth...",
    icon: "",
  },
  {
    title: "Expert opinion",
    description:
      " Our special panel of radiologists with more than 25 years of experience and equivalent...",
    icon: "",
  },
  {
    title: "Peer review using ACR Scoring system",
    description:
      "By promoting accurate diagnoses, improving diagnostic consistency, identifying opportunities...",
    icon: "",
  },
  {
    title: "Nighthawk",
    description:
      " Leveraging advanced technology and a commitment to excellence, our team of experienced... ",
    icon: "",
  },

  {
    title: "Secure Communication",
    description:
      "We use secure communication channels to ensure the privacy and security of patient...",
    icon: "",
  },
  {
    title: "Communication and collaboration between radiologists and referring physicians",
    description:
      "We ensure open and ongoing communication between Radiologists and referring physicians...",
    icon: "",
  },
  {
    title: "Technologist Real-Time Assistance Service Overview",
    description:
      "Technologist Real-Time Assistance Service is a cutting-edge telehealth service that provides...",
    icon: "",
  },
];
