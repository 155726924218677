/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// const imagesPrefix = "assets/images";
import bgImage from "assets/images/img3.jpeg";
import bgImage5 from "assets/images/img5.png";
import bgImage3 from "assets/images/img1.jpg";

export default [
  {
    title: "Teleradiology",
    description: "",
    items: [
      {
        name: "Our teleradiology services allow healthcare organizations and medical practices to access remote radiology interpretation services quickly and efficiently. We provide 24/7 coverage and timely reports, enabling our clients to make informed decisions and provide the best possible care to their patients.",
        image: bgImage5,
      },
    ],
  },
  {
    title: "Preliminary Reports",
    description: "",
    items: [
      {
        name: "We offer preliminary reports to help healthcare providers quickly identify critical findings and initiate appropriate treatment. Our preliminary reports are provided within minutes of receiving images.",
        image: bgImage,
      },
    ],
  },
  {
    title: "Final Reports",
    description: "",
    items: [
      {
        name: " Our final reports provide comprehensive radiology interpretation and diagnosis. Our reports are available within a guaranteed turnaround time to ensure timely and accurate results.",
        image: bgImage3,
      },
    ],
  },
  {
    title: "Quality Assurance",
    description: "",
    items: [
      {
        name: "Our radiologists undergo ongoing training and quality assurance measures to ensure the highest level of accuracy and reliability in our reports. We maintain uniform templates for every report with clear, concise, easy to understand, and a detailed analysis with differentials of the imaging findings with incidental findings which are clinically significant and require prompt action.",
        image: bgImage3,
      },
    ],
  },
  {
    title: "Subspecialty Services",
    description: "",
    items: [
      {
        name: "We offer subspecialty radiology services to provide additional expertise and depth of knowledge in specific areas, such as neurology, MSK, paediatric, cardiology, and oncology.",
        image: bgImage3,
      },
    ],
  },
  {
    title: "Expert opinion",
    description: "",
    items: [
      {
        name: " Our special panel of radiologists with more than 25 years of experience and equivalent expertise are committed to offer second opinions for challenging cases, where healthcare providers require additional insight and guidance from specialists in a particular field.",
        image: bgImage3,
      },
    ],
  },
  {
    title: "Peer review using ACR Scoring system",
    description: "",
    items: [
      {
        name: "By promoting accurate diagnoses, improving diagnostic consistency, identifying opportunities for improvement, minimizing errors and discrepancies, and improving patient safety, peer review helps to ensure that radiological interpretations are of the highest quality, leading to better patient outcomes. The ACR Peer Review Scoring System uses a five-point scoring scale, with scores ranging from 1 (inadequate) to 5 (excellent). The ACR Peer Review Scoring System is an important tool for promoting quality improvement in radiology practice. By providing a standardized method for evaluating radiological interpretations, the system helps to ensure that radiologists meet the highest standards of practice and provide the best possible care to their patients.",
        image: bgImage3,
      },
    ],
  },
  {
    title: "Communication and collaboration between radiologists and referring physicians",
    description: "",
    items: [
      {
        name: "We ensure open and ongoing communication between Radiologists and referring physicians to ensure that all parties are on the same page regarding patient care. This may involve discussing imaging findings, consulting on treatment options, and sharing relevant patient information.",
        image: bgImage3,
      },
    ],
  },
  {
    title: "Secure Communication",
    description: "",
    items: [
      {
        name: "We use secure communication channels to ensure the privacy and security of patient data and maintain HIPAA compliance.",
        image: bgImage3,
      },
    ],
  },
  {
    title: "Nighthawk",
    description: "",
    items: [
      {
        name: " Leveraging advanced technology and a commitment to excellence, our team of experienced radiologists are available overnight and we ensure 24/7 radiology interpretation and reporting. ",
        image: bgImage3,
      },
    ],
  },
  {
    title: "Technologist Real-Time Assistance Service Overview",
    description: "",
    items: [
      {
        name: "Technologist Real-Time Assistance Service is a cutting-edge telehealth service that provides remote real-time assistance to medical technologists and radiology staff. Our team of experienced radiology technologists and support staff are available 24/7 to provide guidance and support to healthcare providers, ensuring accurate and efficient imaging procedures. We can remotely access imaging equipment, providing real-time assistance to staff performing imaging procedures. Our services are designed to improve efficiency, reduce errors, and ensure the best possible outcomes for patients. At Technologist Real-Time Assistance Service, we are committed to supporting healthcare providers with innovative and reliable telehealth solutions.",
        image: bgImage3,
      },
    ],
  },
  // {
  //   title: "Contact Us",
  //   description: "30+ components that will help go through the pages",
  //   items: [
  //     {
  //       image: `${imagesPrefix}/navbars.jpg`,
  //       name: "Navbars",
  //       count: 4,
  //       route: "/sections/navigation/navbars",
  //     },
  //     {
  //       image: `${imagesPrefix}/nav-tabs.jpg`,
  //       name: "Nav Tabs",
  //       count: 2,
  //       route: "/sections/navigation/nav-tabs",
  //     },
  //     {
  //       image: `${imagesPrefix}/pagination.jpg`,
  //       name: "Pagination",
  //       count: 3,
  //       route: "/sections/navigation/pagination",
  //     },
  //   ],
  // },
];
