/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React components
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";
import BackgroundBlogCard from "examples/Cards/BlogCards/BackgroundBlogCard";

// Images
import post1 from "assets/images/examples/imgs1.png";
import post2 from "assets/images/examples/imgs2.jpg";
import post3 from "assets/images/examples/imgs3.jpg";
import post4 from "assets/images/examples/imgs4.jpg";
import post5 from "assets/images/examples/imgs5.jpg";
import post6 from "assets/images/examples/imgs6.png";
import post7 from "assets/images/examples/imgs7.webp";
import post8 from "assets/images/examples/imgs8.jpg";
import post9 from "assets/images/examples/blog2.jpg";

function Places() {
  return (
    <MKBox component="section" py={2}>
      <Container>
        <Grid container item xs={12} lg={6}>
          <MKTypography variant="h3" mb={6}>
            Our 3D services include
          </MKTypography>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={9} lg={9}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} lg={4}>
                <TransparentBlogCard
                  image={post1}
                  title="Neuro Radiology"
                  description="Head CTA/MRA | Neck CTA/MRA | Head CT/MR venography | Head CT/MR perfusion"
                  action={{
                    type: "internal",
                    route: "/contact-us",
                    color: "info",
                    label: "know more",
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <TransparentBlogCard
                  image={post2}
                  title="Facial bones"
                  description="Mandible CT for inferior alveolar nerve |
              Pediatrics CT for craniosynostosis
              "
                  action={{
                    type: "internal",
                    route: "/contact-us",
                    color: "info",
                    label: "know more",
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <TransparentBlogCard
                  image={post3}
                  title="Chest Radiology  "
                  description="Chest CT for tracheal lesion (Virtual Bronchoscopy)|
                  Chest CT for Video assisted Thoracotomy (VAT) planning
              "
                  action={{
                    type: "internal",
                    route: "/contact-us",
                    color: "info",
                    label: "know more",
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <TransparentBlogCard
                  image={post4}
                  title="Cardiac Radiology"
                  description="Cardiac CTA |
              Chest CT/MR for pulmonary vein evaluation prior to ablation |
              Chest CT for pulmonary arteries |
              Cardiac Calcium scoring |
              Pediatric Cardiac CT and MRI in congenital heart disease
              "
                  action={{
                    type: "internal",
                    route: "/contact-us",
                    color: "info",
                    label: "know more",
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <TransparentBlogCard
                  image={post5}
                  title="Vascular Radiology"
                  description="CTA/MRA for abdominal Aortic Aneurysm (AAA) Aortic Aneurysm |
                  CTA/MRA for pre and post op Thoracoabdominal Aneurysm (TAA) |
                  CTA/MRA for Aortic dissection |
                  "
                  action={{
                    type: "internal",
                    route: "/contact-us",
                    color: "info",
                    label: "know more",
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <TransparentBlogCard
                  image={post6}
                  title="Abdominal Radiology"
                  description="Liver Resection/ LiverDonor vascular anatomy |
                  CT Liver Volumes |
                  CT Urography/Hematuria |
                  CT renal donor |
                  MRCP |
                  Pancreas CTA |
                  MRI Liver and Spleen volume for Gaucher’s disease etc
              "
                  action={{
                    type: "internal",
                    route: "/contact-us",
                    color: "info",
                    label: "know more",
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <TransparentBlogCard
                  image={post7}
                  title="Bone and Joint Radiology"
                  description="Skeletal fracture (Spine, face, pelvis, temporal bone and small joints)"
                  action={{
                    type: "internal",
                    route: "/contact-us",
                    color: "info",
                    label: "know more",
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <TransparentBlogCard
                  image={post8}
                  title="Breast Radiology"
                  description="3D MIP of pre and post contrast subtracted MRI images"
                  action={{
                    type: "internal",
                    route: "/contact-us",
                    color: "info",
                    label: "know more",
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={3} lg={3}>
            <BackgroundBlogCard
              image={post9}
              title="3D post-processing"
              description="3D post-processing is a technique used in medical imaging to enhance the visualization and analysis of three-dimensional images. Using specialized software and algorithms, 3D post-processing can generate detailed 3D reconstructions from medical images, such as CT and MRI scans. This technology allows healthcare providers to view and analyze anatomical structures in greater detail, improving their ability to diagnose and treat complex medical conditions. 3D post-processing can also be used to create virtual reality simulations for medical education and training. At its core, 3D post-processing is a powerful tool that enables healthcare providers to gain new insights into the human body, enhancing the accuracy and efficacy of medical procedures and improving patient outcomes."
              action={{
                type: "internal",
                route: "/contact-us",
                label: "know more",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Places;
